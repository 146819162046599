import classNames from 'classnames/bind';
import { graphql } from 'gatsby';
import React from 'react';
import List from '../../../components/common/List/List';
import Intro from '../../../components/Intro/Intro';
import Seo from '../../../components/Seo/Seo';
import * as styles from './Work.module.scss';

const c = classNames.bind(styles);

export const query = graphql`
	query($id: String!, $lang: WpLanguageCodeEnum) {
		page: wpPage(id: { eq: $id }, language: { code: { eq: $lang } }) {
			seo {
				title
				metaDesc
				opengraphTitle
				opengraphDescription
				opengraphSiteName
				opengraphImage {
					uri
				}
				twitterTitle
				twitterDescription
				twitterImage {
					uri
				}
			}
			translations {
				uri
				language {
					locale
				}
			}
			acf: acfPageListing {
				header {
					bgtype
					description
					title
				}
				type
			}
		}
		list: allWpWork(
			limit: 6
			sort: { fields: date, order: DESC }
			filter: { language: { code: { eq: $lang } } }
		) {
			nodes {
				uri
				acf: acfSingleWork {
					description_list
					header {
						bgtype
						description
						title
						image {
							title
							url
						}
						image_mobile {
								title
								url
						}
						vimeoId: popin_video
						video {
							url
							title
							type
						}
					}
				}
			}
		}
	}
`;

export default function WorkListing({ data, pageContext }) {
	const { seo, translations, acf } = data.page;
	return (
		<main className={c('wrapper')}>
			<Seo {...{ seo, translations }} />
			<Intro data={acf.header} />
			<List {...data.list} lang={pageContext.lang} type="Work" />
		</main>
	);
}
